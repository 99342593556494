<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-profile-sidebar w-full sm:w-25rem">
  <div class="flex flex-column mx-auto md:mx-0">
    <div class="mb-3">
      <p-avatar label="{{name}}" size="large" shape="circle"
        [style]="{ 'background-color': '#9c27b0', color: '#ffffff' }"></p-avatar>
    </div>
    <span class="mb-2 font-semibold">Welcome</span>
    <span class="text-color-secondary font-medium mb-5">{{user?.firstName}} {{user?.lastName}}</span>

    <ul class="list-none m-0 p-0">
      <li routerLink="/profile/account">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
          <span>
            <i class="pi pi-user text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Profile</span>
            <p class="text-color-secondary m-0 font-smaller">View or Edit Profile.</p>
          </div>
        </a>
      </li>
      <li (click)="logout()">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
          <span>
            <i class="pi pi-power-off text-xl text-red-500"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Sign Out</span>
            <p class="text-color-secondary m-0 font-smaller">Sign out from your Account.</p>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div class="flex flex-column mt-5 mx-auto md:mx-0">
    <span class="mb-1 font-semibold">Knowledge Base</span>
    <span class="text-color-secondary font-smaller mb-2">Learn Cogwheel Analytics</span>

    <ul class="list-none m-0 p-0">
      <li (click)="openKnowledgeBase()">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
          <span>
            <i class="pi pi-file-word text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Knowledge Base</span>
          </div>
        </a>
      </li>

    </ul>
  </div>

  <div class="flex flex-column mt-5 mx-auto mdÏ:mx-0">
    <span class="mb-1 font-semibold">Hotel Request</span>
    <span class="text-color-secondary font-smaller mb-2">Add or Remove Hotel Request.</span>

    <ul class="list-none m-0 p-0">
      <li (click)="addRemoveHotel('add')">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
          <span>
            <i class="pi pi-plus-circle text-xl text-green-500"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Add Hotel Request</span>
            <!-- <p class="text-color-secondary m-0">Request to add new Hotel</p> -->
          </div>
        </a>
      </li>
      <li (click)="addRemoveHotel('remove')">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
          <span>
            <i class="pi pi-minus-circle text-xl text-red-500"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Remove Hotel Request</span>
            <!-- <p class="text-color-secondary m-0">Request to remove existing Hotel</p> -->
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div class="flex flex-column mt-5 mx-auto md:mx-0">
<<<<<<< HEAD
=======
    <span class="mb-1 font-semibold">Customer Support</span>
    <span class="text-color-secondary font-smaller mb-5">Connect with our support team.</span>

    <!-- Commenting support email -->
    <!-- <div class="mb-3">
      <p class="mb-0">Support:</p>
      <p class="text-sm"><a href="mailto:support@cogwheelmarketing.com">Support&#64;CogwheelMarketing.com</a></p>
    </div> -->
>>>>>>> master

    <div class="mb-3">
      <p class="mb-0">Client Success Manager:</p>
      <span class="text-sm"><a href="mailto:pooja@cogwheelanalytics.com">Pooja&#64;CogwheelAnalytics.com</a></span>
    </div>

    <div class="mb-3">
      <p class="mb-0">Accounting:</p>
      <p class="text-sm"><a href="mailto:cccounting@cogwheelmarketing.com">Accounting&#64;CogwheelMarketing.com</a></p>
    </div>

  </div>

  <!-- <ul class="list-none mt-5 p-0">
    <li>
        <a href="https://legacy.cogwheelanalytics.com/" target="_blank" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
            <span>
                <i class="pi pi-external-link text-xl text-primary"></i>
            </span>
            <div class="ml-3">
                <span class="mb-2 font-semibold">Visit Legacy Analytics</span>
                <p class="text-color-secondary m-0 font-smaller">Legacy platform will be closeed soon</p>
            </div>
        </a>
    </li>
</ul> -->

</p-sidebar>